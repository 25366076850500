import type { IAddress, IAddressV3 } from '@Types/common/address'
import type { IStation, IStationV3 } from '@Types/stations/station'

export const getStationName = ({ address, ...station }: IStation | IStationV3) =>
  (station as IStation).single_name || (station as IStationV3).name || address.locality || ''

export const getStationAddress = (address: IAddress | IAddressV3, country?: string) => {
  const addressV1 = address as IAddress
  const addressV3 = address as IAddressV3

  return `${addressV3?.address ? addressV3?.address + ' ' : ''}${
    addressV1?.address_line1 ? addressV1.address_line1 + ' ' : ''
  }${addressV1?.address_line2 ? addressV1.address_line2 + ' ' : ''}${addressV1?.locality ? addressV1.locality : ''}${
    country ? ', ' + country : ''
  }${addressV3?.country ? ', ' + addressV3.country : ''}`
}

export const getRouteName = ({
  arrival_station,
  departure_station,
}: {
  arrival_station: IStation
  departure_station: IStation
}) => `${getStationName(departure_station)} - ${getStationName(arrival_station)}`
